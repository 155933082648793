import Vue from 'vue'
import App from './App.vue'
import './libs/article-editor/article-editor.min.js';
import './libs/article-editor/plugins/blockcode/blockcode.min.js';
import './libs/article-editor/plugins/reorder/reorder.min.js';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
