<template>
  <div class="cms-page-view">
    <Editor />
</div>
</template>

<script>
import Editor from './components/Editor.vue'

export default {
  name: 'App',
  components: {
    Editor
  }
}
</script>

<style lang="scss">
$navbar-item-img-max-height: 2.5em;
$navbar-height: 5em;
@import './css/bulma.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
