<template>
<div>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="https://www.freshfarm.it">
        <img src="../assets/logo.png">
      </a>
    </div>

    <div class="navbar-menu">

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a v-if="!preview" class="button is-primary" @click="preview = true">
              <strong>Preview</strong>
            </a>
            <a v-else class="button is-seconday" @click="preview = false">
              <strong>Editor</strong>
            </a>
            <a class="button is-light" @click.stop.prevent="copyHtml">
              Esporta HTML
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="container">
    <div class="editor-container">
      <div class="faq-pages" v-show="!preview">
        <article-editor v-model="content" :config="configOptions"></article-editor>
      </div>
      <div v-show="preview">
        <div v-html="content"></div>
      </div>
      <input type="hidden" id="html-code" :value="content">
    </div>
  </div>

</div>
</template>

<script>
import ArticleEditor from './ArticleEditor.vue';
import images from '../dataset/images.json'
import snippets from '../dataset/snippet.json'

export default {
  components: {
    ArticleEditor
  },
  methods: {
    copyHtml () {
    let testingCodeToCopy = document.querySelector('#html-code')
    testingCodeToCopy.setAttribute('type', 'text')
    testingCodeToCopy.select()

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      alert('Testing code was copied ' + msg);
    } catch (err) {
      alert('Oops, unable to copy');
    }

    /* unselect the range */
    testingCodeToCopy.setAttribute('type', 'hidden')
    window.getSelection().removeAllRanges()
  },
},
  data: function() {
    return {
      content: '',
      preview: false,
      configOptions: {
        editor: {
          focus: true,
          minHeight: '500px'
        },
        plugins: ['reorder', 'blockcode'],
        image: {
          select: images
        },
        snippets: {
          json: snippets
        },
        addbar: {
          code: false,
          line: false,
          layer: false
        },
        grid: {
          patterns: ['6|6', '4|8', '8|4', '12'],
          columns: {
            size: 3
          }
        },
        inline: {
          "bold": {
            title: '<span style="font-weight: bold;">## inline.bold ##</span>',
            command: 'inline.format',
            params: {
              tag: 'strong'
            }
          },
          "italic": {
            title: '<span style="font-style: italic;">## inline.italic ##</span>',
            command: 'inline.format',
            params: {
              tag: 'i'
            }
          },
          "underline": {
            title: '<span style="text-decoration: underline;">Underline</span>',
            command: 'inline.format',
            params: {
              tag: 'u'
            }
          },
          "deleted": {
            title: '<span style="text-decoration: line-through;">## inline.deleted ##</span>',
            command: 'inline.format',
            params: {
              tag: 'del'
            }
          }
        },
        format: {
          "p": {
            title: '## format.normal-text ##',
            params: {
              tag: 'p',
              block: 'paragraph'
            }
          },
          "h1": {
            title: '<span style="font-size: 28px; font-weight: bold; font-family: Roboto">## format.large-heading ##</span>',
            params: {
              tag: 'h2',
              block: 'heading'
            }
          },
          "h2": {
            title: '<span style="font-size: 20px; font-weight: bold; font-family: Roboto;">## format.medium-heading ##</span>',
            params: {
              tag: 'h3',
              block: 'heading'
            }
          },
          "h3": {
            title: '<span style="font-size: 16px; font-weight: bold; font-family: Roboto;">## format.small-heading ##</span>',
            params: {
              tag: 'h4',
              block: 'heading'
            }
          },
          "ul": {
            title: '&bull; ## format.unordered-list ##',
            params: {
              tag: 'ul',
              block: 'list'
            }
          },
          "ol": {
            title: '&bull; ## format.ordered-list ##',
            params: {
              tag: 'ol',
              block: 'list'
            }
          }
        }
      }
    }
  }

}
</script>


<style lang="scss">

.editor-container {
    margin: auto;
    max-width: 1110px;
}

.cms-page-view {
  .embed-responsive {
    position: relative;
    padding: 0;
    margin: 0;
    padding-bottom: 56.25%; // 16:9
    height: 0;

      & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  }

  @mixin for-sm {
      @media only screen and (max-width:768px - 1) { @content; }
  }

  p {
    margin-top: 1em;
  }

  h2 {
      font-family: 'Roboto';
      font-weight: 400 !important;
      line-height: 3.6rem;
      font-size: 3.2rem !important;
      margin-top: 0 !important;
      margin-bottom: 2rem !important;
  }

  // Grid
  .grid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 12px;
  }
  .column {
      margin: 0;
  }
  .column-1 { grid-column: span 1; }
  .column-2 { grid-column: span 2; }
  .column-3 { grid-column: span 3; }
  .column-4 { grid-column: span 4; }
  .column-5 { grid-column: span 5; }
  .column-6 { grid-column: span 6; }
  .column-7 { grid-column: span 7; }
  .column-8 { grid-column: span 8; }
  .column-9 { grid-column: span 9; }
  .column-10 { grid-column: span 10; }
  .column-11 { grid-column: span 11; }
  .column-12 { grid-column: span 12; }

  @include for-sm {
      .grid {
          display: block;
      }
  }

  figure {
      &.align-right {
          float: right;
          margin-left: 25px;
          margin-bottom: 25px;
      }
      &.align-left {
          float: left;
          margin-right: 25px;
          margin-bottom: 25px;
      }
  }
  ul.faq-menu {
      background-color: #f4f4f4;

      li {
          padding: 15px;
          height: 50px;
          margin-bottom: 5px;
          border: 1px solid #000;
      }

      a {
          text-decoration: none;
          font-weight: 700;
      }
  }
}
// Embed

</style>
